<template>
  <section id="about" class="about">
    <div class="container">
      <div class="row">
        <div class="col-md-12 aboutsection">
          <div class="col-md-12 col-12">
            <h2 style="color: #0b416c">Marian Rosary</h2>
            <hr />
          </div>
          <div class="row" style="padding-top: 30px">
            <div
              class="col-lg-4 col-md-4 col-12 d-flex align-items-stretch"
              v-for="(obj, key) in prayersJSON"
              :key="key"
            >
              <div class="icon-box">
                <h4 style="font-size: 15px">{{ obj.header }}</h4>
                <p>{{ obj.caption }}</p>
                <a :href="obj.href" class="saintreadmore">Read More</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data: () => ({
    prayersJSON: [
      {
        href: "../prayers/how-to-pray-the-rosary",
        header: "How to pray the rosary",
        caption:
          "On the 5 decades of a rosary, we pray and meditate on the corresponding Mystery from the Joyful Mysteries, the Sorrowful Mysteries, the Glorious Mysteries, or the Luminous Mysteries.",
      },
      {
        href: "../prayers/rosary-prayers",
        header: "Rosary prayers",
        caption:
          "The Rosary prayers are the Apostles Creed, Our Father, Hail Marys, Glory Be, and, if desired, the Fatima Prayer. Next come five mysteries. Rosary prayers conclude with the Hail Holy Queen.",
      },
      {
        href: "../prayers/mysteries-of-the-rosary",
        header: "Mysteries of the rosary",
        caption:
          "The Angel Gabriel appears to Mary, announcing she is to be the Mother of God. Elizabeth greets Mary: “Blessed art Thou amoung women and blessed is the fruit of thy womb”",
      },
    ],
  }),
};
</script>

<style scoped>
.saintreadmore {
  color: #0880e8;
  font-weight: 500;
}
.saintreadmore:hover {
  color: #000;
}
.about {
  padding: 30px;
  background: #f9f9f9;
  position: relative;
}
.aboutsection {
  padding: 0px 30px;
}
.about:before {
  content: "";
  background: rgba(255, 255, 255, 0.75);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

.about .container {
  position: relative;
}

.about .content h2 {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 26px;
}
.icon-box {
  margin-bottom: 20px;
  padding: 20px 20px;
  border-radius: 10px;
  background: #fff;
  border: 1px solid;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}
.icon-box h4 {
  font-weight: 700;
  margin-bottom: 15px;
  color: #000;
}
.icon-box h4 a:hover {
  color: #0880e8;
}
.icon-box:hover {
  background: #f7f9fd;
  border-radius: 10px;
  border-color: #4a90e2;
}
.icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 20px;
}
@media (max-width: 992px) {
  .about {
    padding: 30px;
  }
}
</style>
